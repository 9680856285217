
import Swal from 'sweetalert2'
import { mapMutations } from 'vuex'
// import { BIconX } from 'bootstrap-vue'
import Consent from './Consent.vue'
import ConfirmOtp from './modal/ConfirmOtp.vue'
export default {
  components: {
    // BIconX,
    Consent,
    ConfirmOtp
  },
  props: {
    lineProfile: {
      type: Object,
      default: null,
      required: false
    },
    lineToken: {
      type: String,
      default: null,
      required: false
    },
    isLine: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data () {
    return {
      consent: false,
      consents: {},
      msg: '',
      header: 'ยืนยันตัวตน',
      show: false,
      phone: '',
      code: '',
      verify: 1,
      verifyCode: '',
      register: false,
      login: false,
      email: '',
      otpOption: 'phone',
      showOtpOption: false,
      userId: null,
      confirmed: false
    }
  },
  mounted () {
  },
  methods: {
    ...mapMutations(['showLoading', 'hideLoading', 'setAlert', 'isNumber']),
    async updateLineUser () {
      if (this.isLine) {
        if (this.lineProfile.sub !== undefined) {
          await this.$axios.$post('update-liff/' + this.userId, { user_id: this.lineProfile.sub, token: this.lineToken })
          Swal.fire({
            title: 'คุณได้ทำการเชื่อมต่อบัญชี กับไลน์เรียบร้อยแล้ว',
            icon: 'success'
          }).then(() => {
            window.liff.closeWindow()
          })
        }
      }
    },
    async otp () {
      // console.log('confirm otp', this.phone.length, this.consent)
      if (this.phone.length !== 10 || this.consent === false) {
        if (this.phone.length !== 10) {
          Swal.fire({
            title: 'กรุณาระบุหมายเลขโทรศัพท์ให้ถูกต้องก่อนทำรายการ',
            icon: 'error'
          })
          return
        }

        if (this.consent === false) {
          Swal.fire({
            title: 'กรุณายอมรับเงื่อนไขก่อนเข้าสู่ระบบ',
            icon: 'error'
          })
          return
        }
      }
      this.showLoading()
      const url = 'otp-login'

      try {
        // console.log('try to login')
        const result = await this.$axios.$post(url, { otpOption: this.otpOption, phone: this.phone })
        this.userId = result.data.id

        // call `console` methods as usual
        // console.log('redultt', result)

        // console.log('rssss ===>>> ', result)
        if (result.status === true && this.userId) {
          this.$refs.refModalConfirmOtp.modalShowConfirmOtp()
        } else if (result.status === false) {
          this.setAlert({
            show: true,
            message: result.message,
            header: 'แจ้งเตือน'
          })
        } else {
          this.setAlert({
            show: true,
            header: 'ไม่สามารถทำรายการได้'
          })
        }
      } catch (e) {
        // console.log('errror', e.message)
      }
      // return
      // if (result.status === true && result.data.id) {
      //   console.log('case I', 'is line', this.isLine)
      //   if (this.isLine) {
      //     console.log('case I >>>', 'update-liff/'.result.data.id, { user_id: this.lineProfile.userId, token: this.lineToken })
      //     const resultUpdateLine = await this.$axios.$post('update-liff/'.result.data.id, { user_id: this.lineProfile.userId, token: this.lineToken })
      //     console.log('resulttttt', resultUpdateLine)
      //   } else {
      //     this.$refs.refModalConfirmOtp.modalShowConfirmOtp()
      //   }
      //   // this.verify = 2
      //   return true
      // } else if (result.status === false) {
      //   this.setAlert({
      //     show: true,
      //     message: result.message,
      //     header: 'แจ้งเตือน'
      //   })
      // } else {
      //   this.setAlert({
      //     show: true,
      //     message: result,
      //     header: 'ไม่สามารถทำรายการได้'
      //   })
      // }

      // const url = (this.login === true) ? 'otp-login' : 'otp'
      // try {
      //   const result = await this.$axios.$post(url, { otpOption: this.otpOption, phone: this.phone })
      //   console.log('result>>>', result, this.isLine, result.data)
      //   if (result.status === true && result.data.id) {
      //     console.log('case I', 'is line', this.isLine)
      //     if (this.isLine) {
      //       console.log('case I >>>', 'update-liff/'.result.data.id, { user_id: this.lineProfile.userId, token: this.lineToken })
      //       const resultUpdateLine = await this.$axios.$post('update-liff/'.result.data.id, { user_id: this.lineProfile.userId, token: this.lineToken })
      //       console.log('resulttttt', resultUpdateLine)
      //     } else {
      //       this.$refs.refModalConfirmOtp.modalShowConfirmOtp()
      //     }
      //     // this.verify = 2
      //     return true
      //   } else if (result.status === false) {
      //     this.setAlert({
      //       show: true,
      //       message: result.message,
      //       header: 'แจ้งเตือน'
      //     })
      //   } else {
      //     this.setAlert({
      //       show: true,
      //       message: result,
      //       header: 'ไม่สามารถทำรายการได้'
      //     })
      //   }
      // } catch (e) {
      //   console.log('err', e.message)
      // }
      this.hideLoading()
      return false
    }
  }
}
