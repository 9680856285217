
import { mapGetters, mapState } from 'vuex'

export default {
  data () {
    return {
      items: [],
      patterns: [],
      currentPattern: null,
      menuShow: false,
      height: 0
    }
  },
  computed: {
    ...mapState(['loading', 'alert', 'loadingprice', 'alertWithLine']),
    ...mapGetters(['isAuthenticated'])
  },
  mounted () {
    this.$axios.$get('product-categories').then((res) => {
      this.items = res
    })
    this.$axios.$get('patterns', {
      params: { request_for: 'sme', homepage: true, limit: 12 }
    }).then((res) => {
      this.patterns = res.data
      this.items.map((item) => {
        const pattern = this.patterns.filter(o => o.slug === item.slug)
        item.pattern = pattern[0]
        return item
      })
      this.menuShow = true
    })
  },
  methods: {
    showMenuContent (item) {
      if (item && item.pattern) {
        this.currentPattern = item.pattern
        this.currentPattern.total_url = null
        if (item.total_url) {
          this.currentPattern.total_url = item.total_url
        }
        this.height = item.pattern.patterns.length > 6 ? '520px' : '300px'
        const screenInnerWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
        if (screenInnerWidth < 767.98) {
          if (Array.isArray(this.currentPattern.patterns)) { // fix bug
            this.currentPattern.patterns = this.currentPattern.patterns.slice(0, 5)
          }
        }
        if (screenInnerWidth < 991.98) {
          this.height = item.pattern.patterns.length > 6 ? '450px' : '250px'
        }
        if (screenInnerWidth < 767.98) {
          this.height = item.pattern.patterns.length > 3 ? '400px' : '250px'
        }
        if (item.pattern.patterns.length === 0) {
          this.height = 0
        }
      }
    },
    blurMenuContent () {
      this.height = 0
      this.currentPattern = null
    },
    scrollLeft () {
      this.$refs.categoryMenu.scrollLeft = this.$refs.categoryMenu.scrollLeft - 100
    },
    scrollRight () {
      this.$refs.categoryMenu.scrollLeft = this.$refs.categoryMenu.scrollLeft + 100
    }
  }
}
