
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import ModalAuthen from '~/components/modal/authen.vue'
import TemplateBox from '~/components/modal/TemplateBox.vue'

export default {
  components: {
    ModalAuthen,
    TemplateBox
  },
  data () {
    return {
      keywordText: '',
      userProfile: {},
      menus: [
        {
          text: 'navbar.box_template',
          // badge: 'New',
          to: { name: '' },
          new: false,
          action: 'modalTemplateBoxShow'
        },
        {
          text: 'navbar.all_products',
          to: { name: 'products' },
          new: false
        },
        {
          text: 'navbar.product_samples',
          to: { name: 'sample-products-category', params: { category: 0 } }
        },
        // {
        //   text: 'navbar.product_groups',
        //   to: { name: 'product-groups-category', params: { category: 0 } },
        //   new: false
        // },
        {
          text: 'navbar.my_design',
          to: { name: 'my-designs', query: { flow: 'create' } },
          new: true
        },
        {
          text: 'navbar.faq',
          to: { name: 'faq' },
          new: false
        },
        {
          text: 'navbar.articles',
          to: { name: 'articles', query: { type: 'all' } },
          new: false
        }
      ]
    }
  },
  computed: {
    ...mapState(['userData', 'showModalRequireLogin']),
    ...mapGetters({
      isAuthenticated: 'isAuthenticated',
      isAuthenticating: 'isAuthenticating',
      authUser: 'firebaseUser/getAuthUser'
    })
  },
  watch: {
    showModalRequireLogin (val) {
      if (val) {
        this.showModal('signin')
      }
    },
    '$route.name' (value) {
      if (this.isAuthenticated) {
        try {
          this.$axios.get('me').then((res) => {
            if (res.data && res.data.user) {
              this.$store.commit('setNewAlertCount', {
                new_alert_count: res.data.user.new_alert_count,
                new_alert_order_count: res.data.user.new_alert_order_count,
                new_alert_design_count: res.data.user.new_alert_design_count
              })
            }
          })
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log('error nvb', error)
          this.$cookies.remove('token_' + process.env.NODE_ENV)
          this.signout()
        }
      }
    }
  },
  async mounted () {
    // console.log('navbar is auth', this.isAuthenticated)
    if (this.isAuthenticated) {
      try {
        const res = await this.$axios.get('me')
        // console.log('res', res)
        if (res.data && res.data.user) {
          this.userProfile = res.data.user
          this.$store.commit('setNewAlertCount', {
            new_alert_count: res.data.user.new_alert_count,
            new_alert_order_count: res.data.user.new_alert_order_count,
            new_alert_design_count: res.data.user.new_alert_design_count
          })
        }
      } catch (error) {
        // console.log('catchhhh', error)
        // eslint-disable-next-line no-console
        console.log('nav mounted fail /me/', error)
        // console.log(error)
        this.$cookies.remove('token_' + process.env.NODE_ENV)
        this.signout()
      }
    }
  },
  methods: {
    ...mapActions({
      // setAuthUser: 'firebaseUser/setAuthUser',
      signoutUser: 'firebaseUser/signout',
      clearCookieAll: 'clearCookieAll'
    }),
    ...mapMutations(['showLoading', 'hideLoading']),
    showModal (type) {
      this.$refs['modal-authen'].authenType = type
      this.$refs['modal-authen'].show()
    },
    openCookieSetting () {
      window.scgpdpaCookie.showLearnMore()
    },
    async clearCookie () {
      this.showLoading()
      await this.clearCookieAll()
      await this.hideLoading()
      // await Object.keys(this.$cookies.getAll()).forEach((cookieName) => {
      //   this.$cookies.remove(cookieName)
      // })
      // await this.signout()
    },
    async signout () {
      // const routeName = String(this.$nuxt.$route.path)
      // await this.showLoading()
      await this.$store.dispatch('logout')
      await this.signoutUser()
      // await this.hideLoading()
      // this.setAuthUser(null)

      this.$router.push('/')

      // if (routeName.includes('my/order') || routeName.includes('my/profile') || routeName.includes('my/cart')) {
      //   console.log('signout case I')
      //   // window.location = '/'
      //   console.log('router', this.$router)
      //   // this.$router.push(routeName)
      //   this.$router.push('auth/login')
      // } else {
      //   console.log('signout case II')
      //   this.$router.push('/')
      //   // this.$nuxt.refresh()
      //   // window.location.reload()
      // }
    },
    gotoLoginPage () {
      const redirectUrl = localStorage.getItem('redirect_url')
      if (redirectUrl) {
        this.$router.push(this.localeLocation({ name: 'auth-login-via-phone', query: { redirect: redirectUrl } }))
      } else {
        this.$router.push(this.localeLocation({ name: 'auth-login-via-phone' }))
      }
    },
    search () {
      if (this.keywordText !== '') {
        if (this.$route.name.indexOf('search-result') === 0) {
          // console.log(this.keywordText, this.$route.path)
          window.location = `${this.$route.path}?keyword=${this.keywordText}`
          // this.$router.replace({ query: { keyword: this.keywordText } })
        } else {
          this.$router.push(this.localePath({ name: 'search-result', query: { keyword: this.keywordText } }))
        }
      }
    },
    modalTemplateBoxShow () {
      this.$refs.refModalTemplateBox.modalTemplateBoxShow()
    }
  }
}
