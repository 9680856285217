
// import { formatDateToYMD } from '../../plugins/helpers'
export default {
  data () {
    return {
      gotoLink: 'https://bit.ly/3VrKOz1',
      customWidth: 1200,
      width: '100%',
      height: '90%',
      url: '',
      // imageUrl: 'https://locopack-files.s3.ap-southeast-1.amazonaws.com/homepage/banners/01-Square_1200x1200.png',
      // desktopImageUrl: 'https://locopack-files.s3.ap-southeast-1.amazonaws.com/homepage/banners/01-Square_1200x1200.png',
      // mobileImageUrl: 'https://locopack-files.s3.ap-southeast-1.amazonaws.com/homepage/banners/04-Mobile+740x300.png'
      imageUrl: 'homepage/banners/01-Square_1200x1200.png',
      desktopImageUrl: 'homepage/banners/01-Square_1200x1200.png',
      mobileImageUrl: 'homepage/banners/04-Mobile+740x300.png'
    }
  },
  computed: {
    computedImageUrl () {
      return window.innerWidth >= 768 ? this.desktopImageUrl : this.mobileImageUrl
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  mounted () {
    window.addEventListener('resize', this.handleResize)
  },
  methods: {
    async modalTemplateBoxShow () {
      await this.$firebasePlugin()
      this.$refs['modal-box-template'].show()
    },
    updateDeviceType () {
      const image = window.innerWidth >= 768 ? '01-Square_1200x1200.png' : '04-Mobile+740x300.png'
      this.imageUrl = 'homepage/banners/' + image
      this.customWidth = 740
      // this.imageUrl = image
    },
    handleResize () {
      this.updateDeviceType()
      // Force the computed property to update when the window is resized
      this.$forceUpdate()
    }
  }
}
